import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Photo from "components/Photo"
import photoAltText from "lib/photo-alt-text"
import Layout from "components/Layout"

const PhotoPage = ({ data }) => {
  return (
    <Layout>
      <div css={{ maxWidth: 800, margin: "20px auto" }}>
        <Helmet
          title={photoAltText({
            prefix: data.page.caption ? `${data.page.caption}:` : "",
            author: data.page.author,
            subjects: data.page.subjects,
          })}
        />
        <Photo {...data.page} />
      </div>
    </Layout>
  )
}

export default PhotoPage

export const query = graphql`
  query PhotoPage($_id: String!) {
    page: sanityPhoto(_id: { eq: $_id }) {
      caption
      image {
        ...Image
        ...ImagePresentationData
        asset {
          metadata {
            lqip
            palette {
              sortPalette: lightMuted {
                foreground
                background
                population
              }
            }
          }
        }
      }
      nsfw
      subjects {
        alias
        _id
        slug {
          current
        }
        options {
          buildPage
        }
      }
      author {
        name
      }
    }
  }
`
